body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.fullscreen {
  height: 100vh;
}

.wire {
  border: 1px solid rgb(80, 80, 80);
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-menu {
  width: 20%;
  height: 100%;
}

.display-window {
  height: 100%;
  width: 100%;
  padding: 2%;
  transition: opacity 0.5s ease-in-out;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}
